import { roleDefinitions } from '@/constants/acl-roles'

const getters = {
  /**
   * Returns the current user's data from the store
   * @param {Object} state - Vuex state object
   * @returns {Object} userData - The current user's data
   */
  getUserData(state) {
    return { ...state.userData }
  },
  /**
     * Returns the current user's timezone from the store
     * @param {Object} state - Vuex state object
     * @returns {string} - The current user's timezone
     */
  getUserTimezone(state) {
    return state.userData.timezone
  },
  /**
     * Returns the current user's details (id and email) from the store
     * @param {Object} state - Vuex state object
     * @returns {Object} - The current user's details (id and email)
     */
  getUserDetails(state) {
    return {
      id: state.userData.id,
      userEmail: state.userData.userEmail,
      fullName: state.userData.fullName,
      avatar: state.userData.avatar,
    }
  },
  /**
     * Returns the current user's role from the store
     * @param {Object} state - Vuex state object
     * @returns {string} - The current user's role
     */
  getCurrentRole(state) {
    return state.userData.role
  },
  /**
     * Returns the selected organization's details from the store
     * based on the current user's role.
     * @param {Object} state - Vuex state object
     * @returns {Object} - The selected organization's details (id, name, role, associationId, orgId etc.)
     */
  getSelectedOrgDetails(state) {
    return roleDefinitions[state.userData.role]?.orgType === 'incubator'
      ? {
        ...state.userData.selectedOrg.usersOrganizationtableByIncubator,
        role: state.userData.selectedOrg.role,
        associationId: state.userData.selectedOrg.id,
        orgId: state.userData.selectedOrg.incubator_id,
      }
      : {
        ...state.userData.selectedOrg?.users_organizationtable,
        role: state.userData.selectedOrg.role,
        associationId: state.userData.selectedOrg.id,
        orgId: state.userData.selectedOrg.organization_id,
      }
  },
  /**
     * Returns the associated organization details for the current user
     * from the store.
     * @param {Object} state - Vuex state object
     * @returns {Array<Object>} - The associated organization details (id, name, role, associationId, orgId etc.)
     */
  getAssociatedOrgDetails(state) {
    return state.userData.associatedOrgDetails.map(org => (
      roleDefinitions[org.role]?.orgType === 'incubator'
        ? {
          ...org.usersOrganizationtableByIncubator,
          role: org.role,
          associationId: org.id,
          orgId: org.incubator_id,
        }
        : {
          ...org.users_organizationtable,
          role: org.role,
          associationId: org.id,
          orgId: org.organization_id,
        }))
  },
}
export default getters
