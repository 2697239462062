import Vue from 'vue'
import VueRouter from 'vue-router'

// Routes
import { canNavigate } from '@/libs/acl/routeProtection'
import { Auth } from 'aws-amplify'
import { clearSession } from '@/utils/auth'
import dashboard from './routes/dashboard'
import pages from './routes/pages'
import startups from './routes/incubator-portal/startups'
import mentors from './routes/incubator-portal/mentors'
import programs from './routes/incubator-portal/programs'
import incubators from './routes/incubator-portal/my-incubator'
import mentorPortal from './routes/mentor-portal'
import startupPortal from './routes/startup-portal'
import opportunity from './routes/startup-portal/opportunity'
import performance from './routes/startup-portal/performance'

Vue.use(VueRouter)

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior() {
    return {
      x: 0,
      y: 0,
    }
  },
  routes: [
    {
      path: '/',
      redirect: { name: 'dashboard' },
    },
    ...dashboard,
    ...pages,
    ...startups.map(e => Object.assign(e, {
      meta: {
        ...e.meta,
        resource: 'Incubator',
        action: 'read',
      },
    })),
    ...mentors.map(e => Object.assign(e, {
      meta: {
        ...e.meta,
        resource: 'Incubator',
        action: 'read',
      },
    })),
    ...programs.map(e => Object.assign(e, {
      meta: {
        ...e.meta,
        resource: 'Incubator',
        action: 'read',
      },
    })),
    ...incubators.map(e => Object.assign(e, {
      meta: {
        ...e.meta,
        resource: 'Incubator',
        action: 'read',
      },
    })),
    ...startupPortal.map(e => Object.assign(e, {
      meta: {
        ...e.meta,
        resource: 'Startup',
        action: 'read',
      },
    })),
    ...opportunity.map(e => Object.assign(e, {
      meta: {
        ...e.meta,
        resource: 'Startup',
        action: 'read',
      },
    })),
    ...performance.map(e => Object.assign(e, {
      meta: {
        ...e.meta,
        resource: 'Startup',
        action: 'read',
      },
    })),
    ...mentorPortal.map(e => Object.assign(e, {
      meta: {
        ...e.meta,
        resource: 'Mentor',
        action: 'read',
      },
    })),
    {
      path: '*',
      redirect: 'error-404',
    },
  ],
})

router.beforeEach(async (to, _, next) => {
  const isLoggedIn = await Auth.currentAuthenticatedUser().then(() => true).catch(() => false)

  if (!isLoggedIn) {
    // Logout without redirecting
    await clearSession({ redirect: false })
  }

  if (!canNavigate(to)) {
    if (!isLoggedIn) {
      return next({ name: 'auth-login', query: { redirect: encodeURIComponent(to.fullPath) } })
    }
    return next({ name: 'misc-not-authorized' })
  }

  if (to.meta.redirectIfLoggedIn && isLoggedIn) {
    return next({ name: 'dashboard' })
  }

  return next()
})

export default router
