import { roleAbilities, roleDefinitions } from '@/constants/acl-roles'
import ability from '@/libs/acl/ability'
import router from '@/router'
import * as types from './mutation-types'

const actions = {
  async setUserDetails({ commit }, payload) {
    commit(types.USER_DETAILS_UPDATED, payload)
  },
  async addUserAssociatedOrg({ commit }, payload) {
    commit(types.USER_ASSOCIATED_ORGS_UPDATED, payload)
  },
  async setSelectedOrg({ commit, state, dispatch }, org) {
    const selectedOrg = state.userData.associatedOrgDetails.find(assoc => assoc.id === org.associationId)
    let payload = { selectedOrg }
    const currentRole = state.userData.role
    if (currentRole !== org.role) {
      const updatedRole = roleDefinitions[org.role].role
      payload = {
        role: updatedRole,
        ability: roleAbilities[updatedRole],
        selectedOrg,
      }
      ability.update(payload.ability)
    }
    if (router.currentRoute.name !== 'dashboard') {
      await router.push({ name: 'dashboard' })
    }
    commit(types.USER_SELECTED_ORG_UPDATED, payload)
    await dispatch('organization/fetchAssociatedOrgsBySelectedOrgId', null, { root: true })
  },
}
export default actions
