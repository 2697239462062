import gql from 'graphql-tag'

const ASSOCIATED_ORGS_BY_SELECTED_ORG_ID_QUERY = gql`query associatedOrgsBySelectedOrgId($selectedOrgId: Int!) {
    users_associationtable(where: {incubator_id:{_is_null: false}, organization_id: {_is_null: false}, role: {_in: ["pre-incubatee", "incubatee", "graduate", "startup"]},
        _or: [
            {organization_id: {_eq: $selectedOrgId}},
            {incubator_id: { _eq: $selectedOrgId}}
        ]}) {
        id
        organization_id
        incubator_id
        usersOrganizationtableByIncubator {
            id
            title
        }
    }
}`

export default ASSOCIATED_ORGS_BY_SELECTED_ORG_ID_QUERY
