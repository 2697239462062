import * as types from './mutation-types'

const mutations = {
  [types.USER_DETAILS_UPDATED](state, userData) {
    state.userData = { ...userData }
  },
  [types.USER_ASSOCIATED_ORGS_UPDATED](state, associatedOrg) {
    if (!state.userData) {
      state.userData = {
        associatedOrgDetails: [associatedOrg],
      }
      return
    }
    state.userData = {
      ...state.userData,
      associatedOrgDetails: [
        ...(state.userData.associatedOrgDetails || []),
        associatedOrg,
      ],
    }
  },
  [types.USER_SELECTED_ORG_UPDATED](state, payload) {
    state.userData = {
      ...state.userData,
      ...payload,
    }
  },
}

export default mutations
