import actions from './actions'
import getters from './getters'
import mutations from './mutations'

const userModule = {
  namespaced: true,
  state: {
    userData: {
      id: '',
      userEmail: '',
      role: '',
      timezone: {
        abbr: '',
        text: '',
      },
      ability: [
        {
          action: '',
          subject: '',
        },
      ],

      associatedOrgDetails: [],
      selectedOrg: {},
    },
  },
  getters,
  actions,
  mutations,
}

export default userModule
