import 'regenerator-runtime/runtime'
import Vue from 'vue'
import VueCompositionAPI from '@vue/composition-api'
import { Amplify, Hub } from 'aws-amplify'
import router from './router/index'
import store from './store'
import App from './App.vue' // Global Components
import './global-components' // Import mixins
import './mixins/generalMixin' // 3rd party plugins
import '@axios'
import '@/libs/acl'
import '@/libs/portal-vue'
import '@/libs/clipboard'
import '@/libs/toastification'
import '@/libs/sweet-alerts'
import '@/libs/vue-select'
import '@/libs/tour'

import awsconfig from './aws-exports'
import { createProvider } from './vue-apollo'

Amplify.configure(awsconfig)

// Composition API
Vue.use(VueCompositionAPI)

// Feather font icon
require('@core/assets/fonts/feather/iconfont.css')

// Core styles
require('@core/scss/core.scss')

// Assets styles
require('@/assets/scss/style.scss')

// Vue Good Table CSS
require('@core/scss/vue/libs/vue-good-table.scss')

Vue.config.productionTip = false

let apolloProvider = createProvider()

// Listen for Amplify Auth events (login, logout, etc.)
Hub.listen('auth', data => {
  const { payload } = data
  if (payload.event === 'signIn' || payload.event === 'signOut') {
    apolloProvider = createProvider()
  }
})

new Vue({
  router,
  store,
  apolloProvider,
  render: h => h(App),
}).$mount('#app')
