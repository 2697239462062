import { Ability } from '@casl/ability'
import store from '@/store'
import { initialAbility } from './config'

//  Read ability from localStorage
// * Handles auto fetching previous abilities if already logged in user
const userData = store.getters['user/getUserData']
const existingAbility = userData ? userData.ability : null

export default new Ability(existingAbility || initialAbility)
