import actions from './actions'
import getters from './getters'
import mutations from './mutations'

const organizationModule = {
  namespaced: true,
  state: {
    /** @type {Array<{ id: string, name: string }>} Associated organizations by selected org ID */
    associatedOrgsByOrgId: [],
  },
  getters,
  actions,
  mutations,
}

export default organizationModule
