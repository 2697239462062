import { initialAbility } from '@/libs/acl/config'
import { Auth } from 'aws-amplify'
import ability from '@/libs/acl/ability'
import router from '@/router'

export const getUserData = () => JSON.parse(localStorage.getItem('userData'))

const clearLocalStorage = () => {
  localStorage.removeItem('userData')
}

const resetAbility = () => {
  ability.update(initialAbility)
}

export const clearSession = async ({ redirect = true }) => {
  clearLocalStorage()
  resetAbility()

  try {
    await Auth.signOut()

    if (redirect) {
      await router.push({ name: 'auth-login' })
    }

    return true
  } catch (e) {
    // @TODO: Add error logging
    return false
  }
}
