<template>
  <b-modal
    :visible="visible"
    ok-only
    @hidden="handleClose"
  >
    <template #modal-title>
      <span class="text-capitalize">{{ entity.role ? entity.role : 'Entity' }} </span> Details
    </template>
    <div v-if="loading">
      Loading...
    </div>
    <div v-else-if="error">
      Error: {{ error.message }}
    </div>
    <div v-else-if="entity.role.toLowerCase() === 'student' || entity.role.toLowerCase() === 'mentor'">
      <!-- User Table -->
      <b-table-simple
        bordered
        responsive
      >
        <b-tr>
          <b-td>Full Name</b-td>
          <b-td>{{ entity.full_name }}</b-td>
        </b-tr>
        <b-tr>
          <b-td>Phone</b-td>
          <b-td>{{ entity.phone_number }}</b-td>
        </b-tr>
        <b-tr>
          <b-td>Email</b-td>
          <b-td>{{ entity.email }}</b-td>
        </b-tr>
        <b-tr>
          <b-td>Website</b-td>
          <b-td>
            <b-link
              :href="prependHttps(entity.website)"
              target="_blank"
            >{{ entity.website }}
            </b-link>
          </b-td>
        </b-tr>
        <b-tr>
          <b-td>LinkedIn Profile</b-td>
          <b-td>
            <b-link
              :href="prependHttps(entity.linkedin_profile)"
              target="_blank"
            >{{ entity.linkedin_profile }}
            </b-link>
          </b-td>
        </b-tr>
        <b-tr>
          <b-td>Facebook Profile</b-td>
          <b-td>
            <b-link
              :href="prependHttps(entity.facebook_profile)"
              target="_blank"
            >{{ entity.facebook_profile }}
            </b-link>
          </b-td>
        </b-tr>
      </b-table-simple>
    </div>
    <div v-else>
      <!-- Organisation Table -->
      <b-table-simple
        bordered
        responsive
      >
        <b-tr>
          <b-td>Registered Name</b-td>
          <b-td>{{ entity.registered_title || entity.title }}</b-td>
        </b-tr>
        <b-tr>
          <b-td>Role</b-td>
          <b-td>
            <div class="d-flex align-items-center justify-content-between">
              <b-badge
                class="text-capitalize font-small-4"
                variant="light-primary"
              >
                {{ entity.role === 'startup' ? 'Network' : entity.role }}
              </b-badge>
            </div>
          </b-td>
        </b-tr>
        <b-tr>
          <b-td>Founders</b-td>
          <b-td>
            <span
              v-for="(founder, index) in entity.startups_founderstables"
              :key="index"
            >
              {{ founder.name }} <span v-if="index < entity.startups_founderstables.length - 1">, </span>
            </span>
          </b-td>
        </b-tr>
        <b-tr>
          <b-td>Industry</b-td>
          <b-td>{{ entity.industry }}</b-td>
        </b-tr>
        <b-tr>
          <b-td>Location</b-td>
          <b-td>{{
            [entity.city, entity.state]
              .filter(e => e)
              .join(', ')
          }}
          </b-td>
        </b-tr>
        <b-tr>
          <b-td>Lifecycle Stage</b-td>
          <b-td>{{ entity.startup_stage }}</b-td>
        </b-tr>
        <b-tr>
          <b-td>Website Link</b-td>
          <b-td>
            <b-link
              :href="prependHttps(entity.url)"
              target="_blank"
            >{{ entity.url }}
            </b-link>
          </b-td>
        </b-tr>
        <b-tr>
          <b-td>CIN</b-td>
          <b-td>{{ entity.cin }}</b-td>
        </b-tr>
        <b-tr>
          <b-td>DPIIT</b-td>
          <b-td>{{ entity.dpiit }}</b-td>
        </b-tr>
        <b-tr>
          <b-td>Official Email</b-td>
          <b-td>{{ entity.official_email }}</b-td>
        </b-tr>
        <b-tr>
          <b-td>Official Phone Number</b-td>
          <b-td>{{ entity.official_phone }}</b-td>
        </b-tr>
      </b-table-simple>
    </div>
  </b-modal>
</template>

<script>
import { gql } from 'graphql-tag'
import { newApolloClient } from '@/vue-apollo'
import {
  BTableSimple, BBadge, BLink, BTd, BTr,
} from 'bootstrap-vue'

const GET_ENTITY_DETAILS = gql`
  query getEntityDetails($id: Int!) {
    users_associationtable_by_pk(id: $id) {
     role
     users_organizationtable {
        title
        registered_title
        startups_founderstables{
          name
          phone
          designation
          email
          din_number
          equity_holding
        }
        industry
        city
        state
        startup_stage
        url
        cin
        dpiit
        official_email
        official_phone
      }
      users_customuser {
        full_name
        phone_number
        email
        website
        linkedin_profile
        facebook_profile
      }
    }
  }`

export default {
  components: {
    BTr,
    BBadge,
    BLink,
    BTd,
    BTableSimple,
  },
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
    assocId: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      loading: false,
      error: null,
      entity: {},
    }
  },
  watch: {
    assocId: 'fetchEntityDetails',
  },
  created() {
    this.fetchEntityDetails()
  },
  methods: {
    handleClose() {
      this.$emit('close')
    },
    async fetchEntityDetails() {
      if (!this.assocId) return
      this.loading = true
      try {
        const { data } = await newApolloClient.query({
          query: GET_ENTITY_DETAILS,
          variables() { return { id: this.assocId } },
        })
        if (data.users_associationtable_by_pk.role.toLowerCase() === 'student' || data.users_associationtable_by_pk.role.toLowerCase() === 'mentor') {
          this.entity = { role: data.users_associationtable_by_pk.role, ...data.users_associationtable_by_pk.users_customuser }
        } else {
          this.entity = { role: data.users_associationtable_by_pk.role, ...data.users_associationtable_by_pk.users_organizationtable }
        }
      } catch (error) {
        this.error = error
      } finally {
        this.loading = false
      }
    },
  },
}
</script>

<style scoped>
/* Add your styles here if needed */
</style>
