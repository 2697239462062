import Vue from 'vue'
import Vuex from 'vuex'
import createPersistedState from 'vuex-persistedstate'

// Modules
import app from './app'
import appConfig from './app-config'
import verticalMenu from './vertical-menu'
import userModule from './modules/user/index'
import organizationModule from './modules/organization/index'

const persistState = createPersistedState({
  paths: ['user'],
  fetchBeforeUse: true,
})

Vue.use(Vuex)

export default new Vuex.Store({
  plugins: [persistState],
  modules: {
    user: userModule,
    organization: organizationModule,
    app,
    appConfig,
    verticalMenu,
  },
  strict: process.env.DEV,
})
