/**
 * @typedef {Object} RoleDefinition
 * @property {string|null} orgType - The organization type associated with the role
 * @property {string} role - The role identifier
 * @property {string} displayName - User-friendly name for the role
 */

/**
 * Defines the role-based access control system roles.
 * Note: Legacy roles (superadmin, mentor, startup) are being migrated to their
 * new counterparts (incubator_admin, incubator_mentor, startup_admin).
 * @type {Object.<string, RoleDefinition>}
 */
const roleDefinitions = {
  // @TODO: Remove superadmin role post migration to new role-based access control system. To be replaced by incubator_admin
  admin: {
    orgType: 'incubator',
    role: 'incubator_admin',
    displayName: 'Manager',
  },
  superadmin: {
    orgType: 'incubator',
    role: 'incubator_admin',
    displayName: 'Manager',
  },
  // @TODO: Remove mentor role post migration to new role-based access control system. To be replaced by incubator_mentor
  mentor: {
    orgType: 'incubator',
    role: 'incubator_mentor',
    displayName: 'Mentor',
  },
  // @TODO: Remove startup role post migration to new role-based access control system. To be replaced by startup_admin
  startup: {
    orgType: 'startup',
    role: 'startup_admin',
    displayName: 'Admin',
  },
  incubator_admin: {
    orgType: 'incubator',
    role: 'incubator_admin',
    displayName: 'Manager',
  },
  incubator_mentor: {
    orgType: 'incubator',
    role: 'incubator_mentor',
    displayName: 'Mentor',
  },
  startup_admin: {
    orgType: 'startup',
    role: 'startup_admin',
    displayName: 'Admin',
  },
  user: {
    orgType: 'startup',
    role: 'startup_admin',
    displayName: 'Admin',
  },
  // Post migration:
  // user: {
  //   orgType: null,
  //   role: 'user',
  // ability: [
  //   { action: 'manage', subject: 'Incubator' },
  //   { action: 'read', subject: 'Auth' },
  //   { action: 'read', subject: 'Dashboard' },
  // ],
  //   displayName: 'Self',
  // },
}

const roleAbilities = {
  incubator_admin: [
    { action: 'read', subject: 'Auth' },
    { action: 'read', subject: 'Dashboard' },
    { action: 'manage', subject: 'Incubator' },
  ],
  incubator_mentor: [
    { action: 'read', subject: 'Auth' },
    { action: 'read', subject: 'Dashboard' },
    { action: 'manage', subject: 'Mentor' },
  ],
  startup_admin: [
    { action: 'read', subject: 'Auth' },
    { action: 'read', subject: 'Dashboard' },
    { action: 'manage', subject: 'Startup' },
  ],
  user: [
    { action: 'read', subject: 'Auth' },
    { action: 'read', subject: 'Dashboard' },
    { action: 'read', subject: 'User' },
  ],
}

export { roleDefinitions, roleAbilities }
