import { newApolloClient as apollo } from '@/vue-apollo'
import ASSOCIATED_ORGS_BY_SELECTED_ORG_ID_QUERY from '@/graphql/queries/getAssociatedOrgsBySelectedOrgId'
import * as types from './mutation-types'

const actions = {
  fetchAssociatedOrgsBySelectedOrgId({ commit, rootGetters }) {
    const selectedOrg = rootGetters['user/getSelectedOrgDetails']
    try {
      apollo.query({
        query: ASSOCIATED_ORGS_BY_SELECTED_ORG_ID_QUERY,
        variables: {
          selectedOrgId: selectedOrg.orgId,
        },
      }).then(response => {
        commit(types.ORGANIZATION_ASSOCIATED_ORGS_BY_SELECTED_ORGID_UPDATED, response.data.users_associationtable)
      })
      return true
    } catch {
      return false
    }
  },
}
export default actions
